import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ShareExternalHotelInfoServices {

  private hotelInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setHotelInfo(data: any) {
    this.hotelInfo.next(data);
  }

  public getHotelInfo(): Observable<any> {
    return this.hotelInfo.asObservable();
  }

  public mapPolarisToInternal(hotelInput: any): any {
    // let lessPriceRoom = hotelInput?.rooms?.reduce((a, b) => a.pricing?.sell?.price < b.pricing?.sell?.price ? a : b);

    const hotelOutPut = {
      ...hotelInput,
      name: {
        es: hotelInput?.name
      },
      Contract: {
        name: `${hotelInput?.extraInfo?.HotelchainName}`,
        tag: `${hotelInput?.extraInfo?.HotelchainName}`,
        id: hotelInput?.ContractId
      },
      ContractId: hotelInput?.ContractId,
      description: {
        es: hotelInput?.extraInfo?.Address
      },
      category: hotelInput?.extraInfo?.Stars,
      Images: hotelInput?.extraInfo?.details?.images ? hotelInput?.extraInfo?.details?.images.map((img, index) => {
        return {
          image: img?.url,
          isMain: index === 0,
          fkId: hotelInput?.hotelCode
        }
      }) : [],
      latitude: hotelInput?.extraInfo?.Latitude,
      longitude: hotelInput?.extraInfo?.Longitude,
      startingPrice: hotelInput?.initialPrice,
      startDate: hotelInput?.checkIn,
      endDate: hotelInput?.checkOut,
      currency: hotelInput?.currency,
      installationServices: this.mapPolarisInstallationServices(hotelInput?.extraInfo?.details?.services),
      //TODO hacer esto una variable global o que lo mande API
      // adultAgeLimit:18,
      //TODO HardCode para queno muestre solo Adultos los hoteles de Polaris
      //No tenemos forma de determinar si es solo adulto o no en estos hoteles
      allowChildren:1
    };

    return hotelOutPut;
  }

  private mapPolarisInstallationServices(polarisServices: any[]): Object {

    let services = {};

    polarisServices?.forEach((currentValue) => {
      switch (currentValue?.name) {
        case 'Room service':
          services['hasRoomService'] = true;
          break;
        case '24-hour reception':
          services['hasReception24Hours'] = true;
          break;
        case 'Bathroom':
          if (currentValue?.type === 'ROOM')
            services['hasPrivateBathroom'] = true;
          break;
        case 'Individually adjustable air conditioning':
          services['hasAirConditioning'] = true;
          break;
        case 'TV':
          if (currentValue?.type === 'ROOM')
            services['hasFlatScreenTV'] = true;
          break;

      }
    });

    // const services = {
    //   hasInternet: xxx,
    //   hasParking: xxx,
    //   hasRestaurant: xxx,
    //   hasFamilyRooms: xxx,
    //   hasRoomService: ---,
    //   hasSpa: xxx,
    //   hasSauna: xxx,
    //   hasBar: xxx,
    //   hasReception24Hours: ---,
    //   hasSwimmingPool: xxx,
    //   hasBeach: xxx,
    //   hasBreakfast: xxx,
    //   hasLunch: xxx,
    //   hasDinner: xxx,
    //   hasFlatScreenTV: --,
    //   hasView: xxx,
    //   hasAirConditioning: ---,
    //   hasPrivateBathroom: ---,
    //   hasBathtub: xxx,
    //   hasHydroMassageBathtub: xxx,
    //   hasElectricKettle: xxx,
    //   hasBalcony: xxx,
    //   hasTerrance: xxx,
    // }

    return services;
  }

  public shallowEqual(object1, object2): boolean {

    if (object1.hasOwnProperty('canSelect')) {
      delete object1?.canSelect;
    }
    if (object2.hasOwnProperty('canSelect')) {
      delete object2?.canSelect;
    }
    if (object1.hasOwnProperty('isSelected')) {
      delete object1?.isSelected;
    }
    if (object2.hasOwnProperty('isSelected')) {
      delete object2?.isSelected;
    }

    return JSON.stringify(object1) === JSON.stringify(object2);
  }

}
