import { DisplayCurrency } from '../app/core/interfaces/displayCurrency';
import { Environment } from '../app/core/interfaces/environments';
import packageInfo from '../../package.json';

export const environment: Environment = {
  production: true,
  defaultAgencyName: 'Havanatur',
  defaultCurrency: 'USD',
  defaultDisplayCurrency: DisplayCurrency.symbol,
  ventasActivas: true,
  hasReferredUsers: false,
  includeTransportMenu: false,
  canSetQuotesAccess: false,
  getInfoManyHolders: false,
  hasMultipleAgencies: false,
  showOriginHotelsInBusStop: true,
  showYoomoneyPercentInput: false,
  canSellerEditPricesInCalendar: true,
  includeDownloadBreakdownFacture: false,
  hasRetiredUnnecessaryInputsInReserve: false,
  hasPreReserve: true,
  hasSellRestriction: true,
  timePreChecking: 72,
  showCheckAndWireTransfer: true,
  version: packageInfo.version,
  limit: 5,
  marketType: ['nacional', 'internacional'],
  defaultMarket: {
    lat: 23.131268,
    lng: -82.3582717
  },
  gateways: {
    sber: false,
    cash: false,
    pos: true,
    enzona: true,
    enzonaMlc: true,
    transfermovil: true,
    bidaiondo: true,
    peoplegoto: false,
    adg: false,
    guajipay: false,
    posQr: false,
    linkeao: false,
    credit: false,
    yoomoney: false,
    'in-transit': false,
    check: false,
    bankTransfer: false
  },
  slogan: 'Havanatur',
  genericLogo: 'generic-image.png',
  apiSeg: 'https://apiseg.havanatursa.com/v1/',
  apiUrl: 'https://apihoteles.havanatursa.com/v1/',
  apiAuthenticationUrl: 'https://apihoteles.havanatursa.com/v1/',
  apiSearchHouseUrl: 'https://apicasas.havanatursa.com/v1/',
  apiSearchHotelUrl: 'https://apihoteles.havanatursa.com/v1/',
  apiImageUrl: 'https://cdnturismo.havanatursa.com/',
  clientAgencyMultiService: 'https://gds.havanatursa.com',
  urlOkRedirect: 'https://adminhoteles.havanatursa.com/backend-hotels/hotels-reservations/',
  urlKoRedirect: 'https://adminhoteles.havanatursa.com/backend-hotels/backend-search/',
  logo_voucher: 'assets/images/havanatur/logo-color.png',
  AgencyFrontId: 1,
  agencyName: 'Havanatur',
  address: 'Havanatur',
  phone: 'Havanatur',
  fax: 'Havanatur',
  email: 'sales@havanatur.com',
  googleKey: 'AIzaSyAqiIgEulb61nXgVRmxRNEIcEru4IubI9g',
  defaultLanguage: 'en',
  measurementUnits: [
    {
      id: 'squareMeters',
      name: {
        es: 'Metros cuadrados',
        en: 'Square meters'
      }
    },
    {
      id: 'squareFeet',
      name: {
        es: 'Pies cuadrados',
        en: 'Square feet'
      }
    }
  ],
  smokingPolicyOptions: [
    {
      id: 'smokingProhibited',
      name: {
        es: 'Se prohibe fumar',
        en: 'Smoking prohibited'
      }
    },
    {
      id: 'smokingAllowed',
      name: {
        es: 'Se permite fumar',
        en: 'Smoking is allowed'
      }
    }
  ],
  showDBF: true,
  domain: 'havanatursa.com',
  gdsServices: [
    {
      icon: 'apartment',
      id: 'hotel',
      route: 'https://adminhoteles.havanatursa.com',
      name: 'Hoteles',
      isActive: false
    },
    {
      icon: 'flight',
      id: 'flight',
      name: 'Charter',
      route: 'https://adminvuelos.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_play',
      id: 'experiences',
      name: 'Experiencias',
      route: 'https://adminexperiencias.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_taxi',
      id: 'car',
      route: 'https://adminautos.havanatursa.com/',
      name: 'Autos',
      isActive: true
    },
    {
      icon: 'house',
      id: 'house',
      name: 'Casas',
      route: 'https://admincasas.havanatursa.com/',
      isActive: false
    },
    {
      icon: 'airport_shuttle',
      id: 'transfer',
      name: 'Transfer',
      route: 'https://admintransfer.havanatursa.com/',
      isActive: true
    }
  ],
  hideShowMoreInSearch: true,
  roomsLimit: 15
};
